@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer components{
    .slide{
        @apply   absolute top-0 left-0 w-full h-full opacity-0 translate-x-[-50%] transition-all ease-in 
    }
    .current{
        @apply  opacity-10 translate-x-0  
    }
     
} */